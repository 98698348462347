#project-page {
  .suspense-spinner {
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ion-header > ion-toolbar.ios {
    --background: #edf4fb;
  }

  ion-content::part(background) {
    --background: #edf4fb;
  }
  p {
    font-size: 15px;
    margin: 0;
  }
  h1 {
    margin: -6px 0px 1px 0px;
    font-weight: 700;
    // font-size: 27px;
  }
  .md h1 {
    margin: 7px 0px -3px 0px;
    font-weight: 700;
    font-size: 23px;
    color: #000;
  }
  h4 {
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: -7px;
    font-size: 20px;
  }
  ion-title.md {
    padding-inline: 0px !important;
  }
  .ios ion-segment {
    width: 100%;
    margin: 0px 12px;
  }
  .info-list {
    background: #edf4fb !important;
    ion-item {
      --background: #edf4fb !important;
      h3 {
        font-size: 16px;
      }
    }
  }
  .color-bg {
    --background: #ebf4ff;
    background: #ebf4ff;
  }
  .projname-block {
    margin: 0px 12px;
    // margin: 13px 16px 12px 16px;
    // padding: 8px 16px 8px;
    // background: #255777;
    // color: #fff;
  }
  .details-list {
    background: #edf4fb !important;
    ion-item.detail-item {
      &::part(native) {
        --padding-start: 16px !important;
      }
      --background: #edf4fb !important;
      h3 {
        min-width: 0;
        flex-shrink: 1;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
      span {
        color: rgba(0, 0, 0, 0.99);
      }
    }
  }

  .content-card {
    p {
      color: #000;
    }
  }

  .project-card {
    margin: 16px;
    // ion-card-header {
    //   padding: 12px 15px 0px !important;
    //   .card-title {
    //     font-size: 18px;
    //     margin-top: 3px;
    //     margin-bottom: 2px;
    //     max-width: calc(100% - 30px) !important;
    //   }
    // }
    // ion-card-content {
    //   padding: 5px 14px 7px 15px;
    //   // border-bottom-left-radius: 8px;
    //   // border-bottom-right-radius: 8px;
    // }
    // ion-button::part(native) {
    //   --padding-start: 0px;
    //   --padding-end: 4px;
    // }
  }

  .project-description-full .project-description-text {
    display: block;
    animation: ease-in 600ms;
    mask: none;
  }
  .project-description-full .link {
    display: none;
  }
  .contact-list {
    background: #edf4fb !important;
    margin: 8px 16px;
    border-radius: 10px;
    overflow: hidden !important;
    ion-item {
      --highlight-height: 0px;
      --inner-padding-top: 0px;
      --inner-padding-bottom: 0px;
      --padding-bottom: 0px;
      --padding-top: 0px;
      margin: 10px 0px;
      --inner-padding-start: 16px;
      --inner-padding-end: 5px;
      --padding-start: 0px;
      --background: #ffffff;
      --border-radius: 13px !important;
      --border-width: 1px !important;
      --border-color: rgba(224, 224, 224, 0.692);
      --background-hover-opacity: 0.03;
      --background-focused-opacity: 0;
      --detail-icon-color: #666;
      ion-label {
        margin-left: 16px;
      }
    }

    ion-button {
      margin: 0px 20px 16px 20px;
    }
    .input-clear-icon {
      margin-top: -17px;
    }
    .label-floating {
      margin-left: 2px !important;
    }
    .list-header {
      ion-label {
        margin-top: 16px;
        margin-bottom: 8px;
        --padding-start: 0px;
      }
      ion-label.md {
        font-size: 22px;
        font-weight: 700;
      }
    }
  }
  .form-list {
    margin: 0px 16px;

    ion-item {
      --highlight-height: 0px;
      --inner-padding-top: 0px;
      --inner-padding-bottom: 0px;
      --padding-bottom: 0px;
      --padding-top: 0px;
      margin: 10px 0px;
      --inner-padding-start: 10px;
      --inner-padding-end: 5px;
      --padding-start: 5px;
      --background: #ffffff;
      --border-radius: 13px !important;
      --border-width: 1px;
      --border-color: rgba(224, 224, 224, 0.692);
      --background-hover-opacity: 0.03;
      --background-focused-opacity: 0;
      --detail-icon-color: #fff;
    }
    ion-button {
      margin: 0px 20px 16px 0px;
      font-size: 14px;
    }
    .input-clear-icon {
      margin-top: -17px;
    }
    .label-floating {
      margin-left: 2px !important;
    }
    .list-header {
      ion-label {
        margin-top: 16px;
        margin-bottom: 8px;
        --padding-start: 0px;
      }
      ion-label.md {
        font-size: 22px;
        font-weight: 700;
      }
    }
  }

  .block {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: calc(20px + env(safe-area-inset-left));
    font-size: var(--f7-block-font-size);
  }

  .project-images {
    margin-top: 16px;
    margin-bottom: 16px;
    overflow: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    white-space: nowrap;
    font-size: 0;
    scroll-padding-left: calc(16px + env(safe-area-inset-left));
    &::-webkit-scrollbar {
      display: none;
      opacity: 0;
    }
    img {
      max-height: 120px;
      width: auto;
      border-radius: 16px;
      scroll-snap-align: start start;
      margin-right: 10px;
      background-color: #f1f1f1;
      .theme-dark & {
        background-color: #111;
      }
      @media (min-width: 768px) {
        margin-right: 24px;
      }
    }
    &:after {
      content: '';
      width: 1px;
      height: 1px;
      flex-shrink: 0;
    }
  }
  .poc-span {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.55);
  }
  .project-images-skeleton {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    white-space: nowrap;
    font-size: 0;
    scroll-padding-left: calc(16px + env(safe-area-inset-left));
    &::-webkit-scrollbar {
      display: none;
      opacity: 0;
    }
    .skeleton {
      display: inline-block;
      height: 175px;

      width: 245px;
      border-radius: 16px;
      scroll-snap-align: start start;
      margin-right: 10px;
      background-color: #f1f1f1;
      // .theme-dark & {
      //   background-color: #111;
      // }
      @media (min-width: 768px) {
        margin-right: 24px;
      }
    }
    &:after {
      content: '';
      width: 1px;
      height: 1px;
      flex-shrink: 0;
    }
  }
  .not-found-card {
    margin: 0px 0px 15px 0px;
  }
  .project-card {
    margin: 5px 16px 15px 16px;
    ion-card-header {
      padding: 10px 15px 0px !important;
      .card-title {
        .alert-icon {
          margin-right: 3px;
        }
        font-size: 20px;
        margin-top: 3px;
      }
    }
    ion-card-content {
      padding: 8px 14px 10px 15px;
    }
    ion-button::part(native) {
      --padding-start: 0px;
      --padding-end: 4px;
    }
  }
  .alert-description {
    h3 {
      font-size: 17px;
      font-weight: 700;
      margin: 12px 0px 10px;
      color: #000;
    }
  }

  .info-list {
    background: #ebf4ff !important;
    ion-item {
      --background: #ebf4ff !important;
      h3 {
        font-size: 16px;
      }
    }
  }
  .links-list {
    background: #edf4fb !important;
    margin: 17px 16px;
    padding: 0px !important;
    overflow: hidden !important;
    border-radius: 10px !important;
    ion-item {
      --highlight-height: 0px;
      --inner-padding-top: 0px;
      --inner-padding-bottom: 0px;
      --padding-bottom: 0px;
      --padding-top: 0px;

      --inner-padding-start: 0px;
      --inner-padding-end: 5px;
      --padding-start: 16px;
      --background: #ffffff;
      --border-radius: 10px !important;
      --border-width: 1px !important;
      --border-color: rgba(224, 224, 224, 0);
      --background-hover-opacity: 0.03;
      --background-focused-opacity: 0;
      --detail-icon-color: #666;
      h2 {
        font-size: 16px;
      }
    }
    .links {
      padding: 0 !important;
    }
  }

  #project-information-tab {
    .status-block {
      color: rgba(0, 0, 0, 1);
    }
    .information-list {
      margin: 16px;
      font-size: 13px;
      opacity: 0.7;
    }
  }
}

.dark-theme {
  #project-page {
    ion-content::part(background) {
      --background: #000000;
    }
    ion-list {
      --background: #000000 !important;
      ion-item {
        --background: #0e1113 !important;
        --border-color: #3c3c434a;
      }
    }
    ion-searchbar,
    ion-segment {
      --background: #0e1113;
    }
    .poc-span {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.55);
    }
    .color-bg {
      --background: #000000;
      background: #000000;
    }
    .information-list {
      color: rgba(255, 255, 255, 0.45);
    }
    .contact-card {
      --background: #0e1113;

      --background: #0e1113;
      ion-item {
        --background: #0e1113;
      }
    }
    .form-list {
      background: #0e1113 !important;
      ion-item {
        --background: #111618 !important;
      }
    }
    .details-list,
    .info-list {
      background: #000000 !important;
      ion-item {
        --background: #000000 !important;
      }
    }
    .project-alert-list {
      ion-label {
        span {
          color: #ebebf52e !important;
        }
      }
    }
    .contact-list {
      background: #121212;
      ion-item {
        --background: #121212 !important;
        --border-color: #3c3c434a;
      }
    }
    .resources-list {
      ion-item {
        --background: #000 !important;
      }
    }
    .project-card {
      background: #121212;
    }
  }
  #project-information-tab {
    .status-block {
      color: #999 !important;
    }
  }
  .line-divider {
    border-color: #ffffff !important;
  }
  .details-list {
    ion-item.detail-item {
      --border-color: var(--ion-color-step-150, #d7d8da) !important;
      h3 {
        color: rgba(256, 256, 256, 0.55) !important;
      }
      span {
        color: #fff !important;
      }
    }
  }
}
