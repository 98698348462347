/* @import '@arcgis/core/assets/esri/themes/light/main.css'; */
/* @import 'https://js.arcgis.com/4.20/@arcgis/core/assets/esri/themes/dark/main.css'; */

ion-toolbar.segment-toolbar {
  --opacity: 0;
  --padding-top: 10px;
}
.version-tag {
  text-align: center;
  margin-top: 20px;
  color: #00000080;
  font-size: 14px;
}
.dark-theme .version-tag {
  color: #ffffff80;
}
ion-segment.md-segment {
  --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.065);
}
ion-segment-button.md-segment-button {
  --color: #000;
  --color-checked: var(--ion-color-primary);
}
.dark-theme ion-segment-button.md-segment-button {
  --color: rgb(255, 255, 255);
  --color-checked: rgb(255, 255, 255);
  --indicator-color: rgba(255, 255, 255, 0.171) !important;
}
ion-card-content.alert-card > p {
  margin-top: 1px !important;
  margin-bottom: 6px !important;
  font-size: 14px !important;
  text-overflow: ellipsis !important;
  white-space: unset !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 3 !important;
}

ion-card::part(native) {
  border-radius: 15px !important;
}
ion-content::part(scroll)::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none !important;
}
ion-content::part(scroll),
* {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.dark-theme.md ion-searchbar.projects-searchbar {
  --background: #1a1a1a;
}

ion-title.title-default.ios {
  padding-inline: 74px !important;
}
ion-modal.preferences-modal {
  -ms-flex-align: end;
  align-items: end;
  -ms-flex-pack: end;
  --min-height: 180px;
  --border-radius: 16px 16px 0px 0px;
}
ion-modal.preferences-modal > .modal-wrapper {
  position: relative;
  top: calc(100% - 450px);
}
ion-modal.preferences-modal > .modal-wrapper > .ion-page {
  justify-content: unset;
  position: relative;
  contain: content;
}
ion-modal.preferences-modal
  > .modal-wrapper
  > .ion-page
  > .modal-content-custom {
  overflow: auto;
}
ion-toolbar.preferences-toolbar {
  --opacity: 0 !important;
  padding-top: 0px !important;
}
ion-list.preferences-list {
  background: rgba(0, 0, 0, 0) !important;
  margin-top: 10px;
  padding-bottom: calc(24px + var(--ion-safe-area-bottom, 0));
}

ion-list.preferences-list > ion-item {
  margin: 15px 16px 0px 16px;
  --inner-padding-top: 4px;
  --inner-padding-bottom: 4px;
  --inner-padding-start: 0px;
  --inner-padding-end: 15px;
  --background: #fff;
  --border-radius: 13px !important;
  --border-width: 1px !important;
  --border-color: rgba(224, 224, 224, 0.692);
  --background-hover-opacity: 0.03;
  --background-focused-opacity: 0;
  --background-activated-opacity: 0.04;
  --highlight-height: 0px;
  --detail-icon-color: rgb(0, 0, 0);
}
.dark-theme ion-list.preferences-list,
.dark-theme ion-toolbar.preferences-toolbar {
  background: #121212;
  --background: #121212;
}
.dark-theme ion-header.preferences-header,
.dark-theme ion-modal.preferences-modal > .modal-wrapper {
  background: #121212;
  --background: #121212;
}
.dark-theme ion-list.preferences-list > ion-item {
  --background: #181818;
  --border-color: #3c3c434a;
  --detail-icon-color: rgb(255, 255, 255);
}
.dark-theme ion-label,
.dark-theme ion-list-header {
  color: #fff !important;
}

ion-split-pane {
  --side-width: 250px;
  --side-max-width: 250px;
  --side-min-width: 250px;
}
.hairline-top:before {
  content: '';
  position: relative;
  background-color: #c8c7cc;
  display: block;
  z-index: 15;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  height: 1px;
  width: 100%;
  transform-origin: 50% 0%;
  transform: scaleY(calc(1 / 3));
}
.elevation {
  box-shadow: rgb(0 0 0 / 9%) 0px 1px 12px;
}
.custom-toast-top {
  text-align: center;
  --max-width: auto;
  --width: auto;
  --border-radius: 100px;
  --box-shadow: 0px 5px 12px -8px rgba(0, 0, 0, 0.7);
}
.swiper-pagination-bullet {
  /* background: #fff !important; */
}
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: calc(23px + env(safe-area-inset-bottom)) !important;
}
.bottom-toast::part(message) {
  font-size: 15px;
}
.bottom-toast::part(button) {
  font-size: 14px;
  font-weight: 500;
}
.module {
  margin: 0 0 1em 0;
  overflow: hidden;
}
.module p {
  margin: 0;
}
.overflow {
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem; /* space for ellipsis */
}
.overflow::before {
  position: absolute;
  content: '...';
  bottom: 0;
  right: 0;
}

.overflow::after {
  content: '';
  position: absolute;
  /*   inset-inline-end: 0; */
  right: 0;
  width: 1rem;
  height: 1rem;
  background: white;
}

.list-footer-space {
  height: 10px;
}

.list-md {
  padding-top: 0px;
  padding-bottom: 0px;
}

.alert-button.sc-ion-alert-ios:last-child {
  font-weight: normal;
}

.list-spinner {
  width: 100%;
  height: 100px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.suspense-spinner {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-content-md p {
  color: #4c4c4c !important;
}

.no-resizing,
ion-header,
ion-title,
ion-tab-bar,
ion-chip > ion-icon,
ion-icon,
#map-view,
.svg-inline--fa {
  text-size-adjust: none !important;
  -webkit-text-size-adjust: none !important;
}

.line-divider {
  margin: 0px 16px;
  padding-left: 0px;
  padding-right: calc(0px + var(--inner-padding-end));
  padding-right: calc(
    var(--ion-safe-area-right, 0px) + var(--inner-padding-end)
  );
  padding-top: 0px;
  padding-bottom: px;
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-flex: 1 1;
  flex: 1 1;
  -webkit-flex-direction: inherit;
  flex-direction: inherit;
  -webkit-align-items: inherit;
  align-items: inherit;
  -webkit-align-self: stretch;
  align-self: stretch;
  min-height: inherit;
  border-width: 0 0 0.55px 0;
  border-style: solid;
  border-color: #000;
  overflow: inherit;
  box-sizing: border-box;
  opacity: 0.159;
}

/*
 * Ionic Variables and Theming
 * ----------------------------------------------------------------------------
 * For more information, please see
 * https://www.ionicframework.com/docs/theming/
 */

/*
 * Ionic Colors
 * ----------------------------------------------------------------------------
 * Named colors make it easy to reuse colors on various components.
 * It's highly recommended to change the default colors
 * to match your app's branding. Ionic provides nine layered colors
 * that can be changed to theme an app. Additional colors can be
 * added as well (see below). For more information, please see
 * https://www.ionicframework.com/docs/theming/colors
 *
 * To easily create custom color palettes for your app’s UI,
 * check out our color generator:
 * https://www.ionicframework.com/docs/theming/color-generator
 */

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}
.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}
.ion-color-completed {
  --ion-color-base: var(--ion-color-completed);
  --ion-color-base-rgb: var(--ion-color-completed-rgb);
  --ion-color-contrast: var(--ion-color-completed-contrast);
  --ion-color-contrast-rgb: var(--ion-color-completed-contrast-rgb);
  --ion-color-shade: var(--ion-color-completed-shade);
  --ion-color-tint: var(--ion-color-completed-tint);
}
.ion-color-planning {
  --ion-color-base: var(--ion-color-planning);
  --ion-color-base-rgb: var(--ion-color-planning-rgb);
  --ion-color-contrast: var(--ion-color-planning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-planning-contrast-rgb);
  --ion-color-shade: var(--ion-color-planning-shade);
  --ion-color-tint: var(--ion-color-planning-tint);
}
.ion-color-indesign {
  --ion-color-base: var(--ion-color-indesign);
  --ion-color-base-rgb: var(--ion-color-indesign-rgb);
  --ion-color-contrast: var(--ion-color-indesign-contrast);
  --ion-color-contrast-rgb: var(--ion-color-indesign-contrast-rgb);
  --ion-color-shade: var(--ion-color-indesign-shade);
  --ion-color-tint: var(--ion-color-indesign-tint);
}
.ion-color-inconstruction {
  --ion-color-base: var(--ion-color-inconstruction);
  --ion-color-base-rgb: var(--ion-color-inconstruction-rgb);
  --ion-color-contrast: var(--ion-color-inconstruction-contrast);
  --ion-color-contrast-rgb: var(--ion-color-inconstruction-contrast-rgb);
  --ion-color-shade: var(--ion-color-inconstruction-shade);
  --ion-color-tint: var(--ion-color-inconstruction-tint);
}
.ion-color-brown {
  --ion-color-base: var(--ion-color-brown);
  --ion-color-base-rgb: var(--ion-color-brown-rgb);
  --ion-color-contrast: var(--ion-color-brown-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brown-contrast-rgb);
  --ion-color-shade: var(--ion-color-brown-shade);
  --ion-color-tint: var(--ion-color-brown-tint);
}
/* body {
  background: radial-gradient(
    circle,
    rgb(232, 248, 255) 0%,
    rgb(203, 227, 255) 100%
  );
} */
:root {
  /* --ion-padding: 20px; */
  /* --background: radial-gradient(
    circle,
    rgb(232, 248, 255) 0%,
    rgb(203, 227, 255) 100%
  ); */

  /** primary **/
  --ion-color-primary: #0072bc;
  --ion-color-primary-rgb: 0, 114, 188;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0064a5;
  --ion-color-primary-tint: #1a80c3;
  --ion-color-primary-dark: #00568f;

  /** secondary **/
  --ion-color-secondary: #8dc63f;
  --ion-color-secondary-rgb: 141, 198, 63;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #7cae37;
  --ion-color-secondary-tint: #98cc52;

  /** tertiary **/
  --ion-color-tertiary: #a317a8;
  --ion-color-tertiary-rgb: 163, 23, 168;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #8f1494;
  --ion-color-tertiary-tint: #ac2eb1;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #808188;
  --ion-color-medium-rgb: 128, 129, 136;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #73737a;
  --ion-color-medium-tint: #8d8e96;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #ffffff;
  --ion-color-white-tint: #ffffff;

  /** black **/
  --ion-color-black: #000000;
  --ion-color-black-rgb: 255, 255, 255;
  --ion-color-black-contrast: #000000;
  --ion-color-black-contrast-rgb: 0, 0, 0;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #000000;

  --ion-color-indesign: #0072bc;
  --ion-color-indesign-rgb: 0, 114, 188;
  --ion-color-indesign-contrast: #ffffff;
  --ion-color-indesign-contrast-rgb: 255, 255, 255;
  --ion-color-indesign-shade: #0064a5;
  --ion-color-indesign-tint: #1a80c3;

  --ion-color-inconstruction: #f58a00;
  --ion-color-inconstruction-rgb: 245, 138, 0;
  --ion-color-inconstruction-contrast: #000000;
  --ion-color-inconstruction-contrast-rgb: 0, 0, 0;
  --ion-color-inconstruction-shade: #d87900;
  --ion-color-inconstruction-tint: #f6961a;

  --ion-color-completed: #259e3c;
  --ion-color-completed-rgb: 37, 158, 60;
  --ion-color-completed-contrast: #ffffff;
  --ion-color-completed-contrast-rgb: 255, 255, 255;
  --ion-color-completed-shade: #218b35;
  --ion-color-completed-tint: #3ba850;

  --ion-color-planning: #eb394d;
  --ion-color-planning-rgb: 235, 57, 77;
  --ion-color-planning-contrast: #ffffff;
  --ion-color-planning-contrast-rgb: 255, 255, 255;
  --ion-color-planning-shade: #cf3244;
  --ion-color-planning-tint: #ed4d5f;

  --ion-color-brown: #71462b;
  --ion-color-brown-rgb: 113, 70, 43;
  --ion-color-brown-contrast: #ffffff;
  --ion-color-brown-contrast-rgb: 255, 255, 255;
  --ion-color-brown-shade: #633e26;
  --ion-color-brown-tint: #7f5940;
}

/*
 * Additional Ionic Colors
 * ----------------------------------------------------------------------------
 * In order to add colors to be used within Ionic components,
 * the color should be added as a class with the convention `.ion-color-{COLOR}`
 * where `{COLOR}` is the color to be used on the Ionic component.
 * For more information on adding new colors, please see
 * https://ionicframework.com/docs/theming/colors#adding-colors
 *
 * To generate the code for a new color, check out our new color creator:
 * https://ionicframework.com/docs/theming/colors#new-color-creator
 */

:root {
  --ion-color-favorite: #69bb7b;
  --ion-color-favorite-rgb: 105, 187, 123;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255, 255, 255;
  --ion-color-favorite-shade: #5ca56c;
  --ion-color-favorite-tint: #78c288;

  --ion-color-twitter: #1da1f4;
  --ion-color-twitter-rgb: 29, 161, 244;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255, 255, 255;
  --ion-color-twitter-shade: #1a8ed7;
  --ion-color-twitter-tint: #34aaf5;

  --ion-color-instagram: #5956d8;
  --ion-color-instagram-rgb: 89, 86, 216;
  --ion-color-instagram-contrast: #ffffff;
  --ion-color-instagram-contrast-rgb: 255, 255, 255;
  --ion-color-instagram-shade: #4e4cbe;
  --ion-color-instagram-tint: #6a67dc;

  --ion-color-vimeo: #23b6ea;
  --ion-color-vimeo-rgb: 35, 182, 234;
  --ion-color-vimeo-contrast: #ffffff;
  --ion-color-vimeo-contrast-rgb: 255, 255, 255;
  --ion-color-vimeo-shade: #1fa0ce;
  --ion-color-vimeo-tint: #39bdec;

  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;
}

.ion-color-favorite {
  --ion-color-base: var(--ion-color-favorite);
  --ion-color-base-rgb: var(--ion-color-favorite-rgb);
  --ion-color-contrast: var(--ion-color-favorite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
  --ion-color-shade: var(--ion-color-favorite-shade);
  --ion-color-tint: var(--ion-color-favorite-tint);
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-instagram {
  --ion-color-base: var(--ion-color-instagram);
  --ion-color-base-rgb: var(--ion-color-instagram-rgb);
  --ion-color-contrast: var(--ion-color-instagram-contrast);
  --ion-color-contrast-rgb: var(--ion-color-instagram-contrast-rgb);
  --ion-color-shade: var(--ion-color-instagram-shade);
  --ion-color-tint: var(--ion-color-instagram-tint);
}

.ion-color-vimeo {
  --ion-color-base: var(--ion-color-vimeo);
  --ion-color-base-rgb: var(--ion-color-vimeo-rgb);
  --ion-color-contrast: var(--ion-color-vimeo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vimeo-contrast-rgb);
  --ion-color-shade: var(--ion-color-vimeo-shade);
  --ion-color-tint: var(--ion-color-vimeo-tint);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-github {
  --ion-color-base: #211f1f;
  --ion-color-base-rgb: 33, 31, 31;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1d1b1b;
  --ion-color-tint: #373535;
}

.ion-color-instagram {
  --ion-color-base: #9537bc;
  --ion-color-base-rgb: 149, 55, 188;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #8330a5;
  --ion-color-tint: #a04bc3;
}

/*
 * Shared Variables
 * ----------------------------------------------------------------------------
 * To customize the look and feel of this app, you can override
 * the CSS variables found in Ionic's source files.
 * To view all of the possible Ionic variables, see:
 * https://ionicframework.com/docs/theming/css-variables#ionic-variables
 */

:root {
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
}

/*
 * App iOS Variables
 * ----------------------------------------------------------------------------
 * iOS only CSS variables can go here
 */

.ios {
  /* --ion-toolbar-background: var(--ion-color-primary); */
  /* --ion-tab-bar-background: #ffffff; */
}

/*
 * App Material Design Variables
 * ----------------------------------------------------------------------------
 * Material Design only CSS variables can go here
 */

.md {
  --ion-toolbar-background: var(--ion-color-primary-dark);
  --ion-toolbar-color: var(--ion-color-white);
  --ion-toolbar-segment-color: var(--ion-color-white);
  --ion-toolbar-segment-color-checked: var(--ion-color-secondary-tint);
  /* --ion-toolbar-segment-color-checked: var(--ion-color-white); */
}

/*
 * App Theme
 * ----------------------------------------------------------------------------
 * Ionic apps can have different themes applied, which can
 * then be further customized. These variables come last
 * so that the above variables are used by default.
 */

/*
 * Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme {
  /* --ion-background-color: #000; */

  --ion-color-primary: #0072bc;
  --ion-color-primary-rgb: 0, 114, 188;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0064a5;
  --ion-color-primary-tint: #1a80c3;
  --ion-color-primary-dark: #0c2245;

  --ion-color-secondary: #8dc63f;
  --ion-color-secondary-rgb: 141, 198, 63;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #7cae37;
  --ion-color-secondary-tint: #98cc52;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #eb394d;
  --ion-color-danger-rgb: 235, 57, 77;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3244;
  --ion-color-danger-tint: #ed4d5f;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.ios {
  --ion-tab-bar-background: #0f1113 !important;
  --ion-toolbar-background: #0f1113;
  --ion-item-background: #000000;
  --ion-toolbar-segment-indicator-color: rgb(49, 49, 51);
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
}

/*
 * Material Design Dark Theme
 * ----------------------------------------------------------------------------
 */
/* ion-title.title-large {
  color: #fff;
} */

.dark-theme .md {
  --ion-toolbar-segment-color-checked: var(--ion-color-primary);
  /* --ion-toolbar-segment-background-checked: #000; */
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #121212;
  /* 
  --ion-toolbar-background: #1c1c1eff;
  --ion-tab-bar-background: #1c1c1eff; */
  --ion-toolbar-background: #0f1113;
  --ion-tab-bar-background: #0f1113;
}
