#tutorial-page {
  background: #ffffff;

  ion-content::part(background) {
    --background: #ffffff;
  }
  ion-toolbar {
    --background: transparent;
    --border-color: transparent;
  }
  .swiper-container {
    height: 90% !important;
  }
  .continue-container {
    height: 10%;
    text-align: center;
  }
  .swiper-pagination-bullet {
    background: #0072bc !important;
  }

  .swiper-slide {
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .parallax-bg {
    height: 100%;
    width: 500%;
    background-position: bottom;
    background-size: cover;
    // background-repeat: repeat-y;
  }

  .slide-title {
    margin-top: 30px;
    // text-align: center;
    padding: 0px 30px;
  }
  .slide-image-wrap {
    img {
      max-width: 70%;
    }
  }
  .slide-image-wrap2 {
    img {
      max-width: 80%;
    }
  }
  .slide-image {
    width: 75%;
    max-width: 500px;
    pointer-events: none;
  }
  .slide-image-t3 {
    width: 85%;
    padding: 0 30px;
    max-width: 500px;
    margin-bottom: 30px;
  }
  .slide-image-3 {
    width: 60%;
    margin-bottom: 30px;
    margin-top: 20px;
    padding: 0 30px;
    max-width: 400px;
  }
  .slide-image-4 {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 20px;
    padding: 0 0px;
    max-width: 500px;
  }
  h2 {
    margin-top: -10px;
    font-size: 25px;
    font-weight: 300;
    text-align: center;
  }
  b {
    font-weight: 700;
  }

  p {
    margin: 0 auto;
    max-width: 500px;
    text-align: center;
    padding: 0 30px;
    font-size: 16px;
    line-height: 1.3;
    color: var(--ion-color-step-600, #60646b);

    b {
      color: var(--ion-text-color, #000000);
    }
  }
}

.dark-theme {
  #tutorial-page {
    background: #000000;
    ion-content::part(background) {
      --background: #000000;
    }
  }
}
