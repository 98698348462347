#preferences-page {
  ion-toolbar.main-menu.ios {
    --padding-start: 12px;
  }
  ion-content::part(background) {
    --background: #fff;
  }
  .color-bg {
    --background: #fff;
  }
  h1 {
    font-size: 21px;
    font-weight: 700;
    margin-left: 0px;
  }
  h2 {
    font-size: 17px;
    font-weight: 700;
    margin-left: 0px;
    margin-top: 13px;
    margin-bottom: 4px;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
  }
  p {
    font-size: 14px;
    margin-top: 0px;
  }
  ul {
    padding-left: 16px;
    margin-top: 10px;
    padding-right: 16px;
    li {
      margin-top: 5px;
      line-height: 1.4rem;
      ion-chip.status {
        margin-top: -6px;
        margin-bottom: -3px;
      }
    }
  }

  .map-logos {
    // position: absolute;
    // top: 0px;
    // left: 0px;
    margin-top: 10px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 40px;
    img {
      width: 40%;
      height: auto;
      padding: 6px;
      margin: 10px 0px;
      line-height: 150px;
      color: white;
    }
    .cot {
      padding-bottom: 0px !important;
      margin-bottom: 0px;
    }
    .tt {
      width: 60% !important;
      padding: 0px !important;
      margin-top: 20px;
      margin-bottom: 40px;
    }
    .pipes {
      padding-bottom: 0px !important;
      margin-bottom: 0px;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  .privacy-list {
    ion-list-header {
      font-size: 17px;
    }
    h2 {
      font-size: 17px;
      font-weight: 600;
      margin-left: 0px;
    }
    ion-label {
      h2 {
        font-size: 22px;
        font-weight: 700;
      }
      margin: 5px 5px 20px 0px;
      text-overflow: ellipsis;
      white-space: unset;
      overflow: initial;
      display: -webkit-box;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 50;
      p {
        margin-top: 3px;
      }
    }
  }
}
.radio-list {
  ion-list-header {
    font-size: 19px;
  }
  h2 {
    font-size: 17px;
    font-weight: 600;
    margin-left: 0px;
  }
  ion-label {
    h2 {
      font-size: 22px;
      font-weight: 700;
    }
    text-overflow: ellipsis;
    white-space: unset;
    overflow: initial;
    display: -webkit-box;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 50;
    p {
      margin-top: 3px;
    }
  }
}
.dark-theme {
  #preferences-page {
    ion-content::part(background) {
      --background: #000;
    }
    ion-list {
      background: #000;
      ion-item {
        --background: #000;
      }
    }
    .color-bg {
      --background: #0e1113;
    }
    .project-card {
      --background: #15181b;
    }
  }
}
