.span {
  margin-left: 8px;
  color: #000;
}

.dark-theme {
  .span {
    color: #999999;
  }
}
