ion-page {
  // background: #ebf4ff;
}
// ion-content::part(background) {
//   background: #ebf4ff;
// }
.color-bg {
  --background: #ebf4ff;
  background: #ebf4ff;
}
.md {
  .card-grid {
    margin-top: 8px;
  }
}
.ios {
  .header-background {
    background: #ebf4ff;
  }
  ion-searchbar {
    padding-bottom: 0px;
    padding-inline-end: 10px;
    padding-inline-start: 10px;
  }
}
h4 {
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: -7px;
  font-size: 20px;
}
ion-toolbar.grip {
  --padding-top: 20px;
  --padding-bottom: 3px;
  --min-height: 16px;
}
ion-item {
  --transition: none !important;
}
.no-results-wrap {
  padding: 0px 13px;
  h2 {
    margin-top: 0px !important;
    font-weight: 700;

    margin-bottom: -7px;
    font-size: 20px;
  }
  p {
    margin: 5px 0px 10px 0px;
  }
}
.not-found-card {
  margin: 0px 0px 15px 0px;
}
.favorite-toggle-card {
  width: 100%;
  text-align: right;
  height: 0px;
}
ion-button.favorite-toggle {
  position: relative;
  top: 0px;
  z-index: 1000;
  // right: -6px;
}
.card-grid {
  padding: 5px 8px;
  &.ios {
    margin-top: -10px;
  }
}
.ios .modal-header ion-toolbar {
  --background: #fff;
}
.dark-theme {
  .ios .modal-header ion-toolbar {
    --background: none;
  }
}

.project-card {
  // margin: 8px 12px 13px 12px;
  margin: 0px 3px 5px 3px;
  ion-card-header {
    padding: 12px 15px 0px !important;
    .card-title {
      font-size: 20px;
      margin-top: 10px;
    }
  }
  ion-card-content {
    padding: 8px 14px 10px 15px;
  }
  ion-button::part(native) {
    --padding-start: 0px;
    --padding-end: 4px;
  }
  ion-button.see-more {
    height: 1.1em;
    margin-right: -3px;
  }
  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.details-list {
  border-radius: 10px;
  overflow: hidden !important;
  ion-item {
    // --border-color: #000000 !important;
    --background: var(--ion-color-white) !important;
    overflow: hidden !important;
    h3 {
      font-size: 16px;
    }
  }
  .list-header {
    ion-label {
      margin-top: 16px;
      margin-bottom: 8px;
    }
    ion-label.md {
      font-size: 22px;
      font-weight: 700;
    }
  }
  .contact-button-item.md {
    --padding-start: 30px;
  }
}

ion-segment.ios-segment-md {
  width: 100% !important;
  ion-segment-button.custom {
    --color: var(--ion-color-primary) !important;
    --margin-top: 0px;
    --margin-bottom: 0px;
  }
}
