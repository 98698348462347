#projectsTab {
  background: #ebf4ff !important;
  ion-content::part(background) {
    --background: #ebf4ff !important;
  }
  .color-bg {
    --background: #ebf4ff;
    background: #ebf4ff;
  }
  .md {
    .card-grid {
      margin-top: -7px;
    }
  }
  .ios {
    ion-searchbar {
      padding-bottom: 0px;
      padding-inline-end: 8px;
      padding-inline-start: 8px;
    }
  }
  .projname-block {
    margin: 0px 12px;
    h1 {
      margin: -6px 0px 1px 0px;
      font-weight: 700;
      font-size: 33px;
    }
  }
  .no-results-wrap {
    padding: 0px;
    .card-title {
      font-size: 18px !important;
      margin-bottom: -7px;
      font-size: 20px;
    }
    p {
      margin: 5px 0px 5px 0px;
    }
  }
  .not-found-card {
    margin: 0px 0px 15px 0px;
  }
  .favorite-toggle-card {
    width: 100%;
    text-align: right;
    height: 0px;
  }
  ion-button.favorite-toggle {
    position: relative;
    top: 0px;
    z-index: 1000;
    right: 5px;
  }
  .details-list {
    border-radius: 10px;
    overflow: hidden !important;
    ion-item {
      --border-color: #000000 !important;
      --background: var(--ion-color-white) !important;
      overflow: hidden !important;
      h3 {
        font-size: 16px;
      }
    }

    .list-header {
      ion-label {
        margin-top: 16px;
        margin-bottom: 8px;
      }
      ion-label.md {
        font-size: 22px;
        font-weight: 700;
      }
    }
    .contact-button-item.md {
      --padding-start: 30px;
    }
  }
}

.dark-theme {
  #projectsTab {
    background: #000000;
    ion-content::part(background) {
      --background: #000000;
    }
    .color-bg {
      --background: #000000;
      background: #000000;
    }
    .project-card {
      --background: #0e1113;
    }
    ion-searchbar,
    ion-segment {
      --background: #0e1113;
    }
  }
}
