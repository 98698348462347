#lightboxModal {
  // --width: 100vw !important;
  // --height: 100vh !important;
  .lightbox-header {
    display: block !important ;
    background: var(--ion-color-primary) !important;
    ion-toolbar {
      --min-height: 56px;
      --border-width: 0px;
      --min-height: 56px;
      --opacity: 0.9;
    }
  }
  .swiper-container {
    // background:
    // background: rgb(21, 21, 21) none repeat scroll 0% 0% !important;
    background: #ebf4ff !important;
  }

  .swiper-pagination-bullet {
    background: #0e1113;
  }
  .swiper-zoom-container {
    text-align: center;
    max-height: 90%;
    img {
      width: auto;
      height: 90%;
    }
  }
  .image-caption {
    height: 10%;
    text-align: center;
    p {
      color: rgb(30, 30, 30) !important;
      margin-top: -10px;
    }
  }
}
.dark-theme {
  #lightboxModal {
    .lightbox-header {
      background: #0e1113 !important;
    }
    ion-toolbar,
    ion-content::part(background) {
      --background: #0e1113;
    }
    .swiper-container {
      // background:
      background: #000 !important;
      // background: #ebf4ff !important;
    }
    .swiper-pagination-bullet {
      background: #fff;
    }
    .image-caption {
      p {
        color: rgb(255, 255, 255) !important;
      }
    }
  }
}
