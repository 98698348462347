#storeRedirect {
  background: #ebf4ff !important;
  ion-content::part(background) {
    --background: #ebf4ff !important;
  }
  .color-bg {
    --background: #ebf4ff;
    background: #ebf4ff;
  }
  .slide-image {
    width: 75%;
    max-width: 500px;
    pointer-events: none;
  }
}

// .dark-theme {
//   #projectsTab {
//     background: #000000;
//     ion-content::part(background) {
//       --background: #000000;
//     }
//     .color-bg {
//       --background: #000000;
//       background: #000000;
//     }
//     .project-card {
//       --background: #0e1113;
//     }
//     ion-searchbar,
//     ion-segment {
//       --background: #0e1113;
//     }
//   }
// }
