#alertsTab {
  background: #ebf4ff;
  ion-content::part(background) {
    --background: #ebf4ff;
  }
  .color-bg {
    --background: #ebf4ff;
    background: #ebf4ff;
  }
  .md {
    .card-grid {
      margin-top: -7px;
    }
  }
  .card-grid {
    padding: 6px 7px;
    &.ios {
      margin-top: -7px;
    }
  }
  .projname-block {
    margin: 0px 12px;
    h1 {
      margin: -6px 0px 1px 0px;
      font-weight: 700;
      font-size: 33px;
    }
  }
  .no-results-wrap {
    padding: 4px 12px;
    .card-title {
      font-size: 18px !important;
      font-size: 20px;
    }
    p {
      margin: -2px 0px 10px 0px !important;
    }
  }
  .not-found-card {
    margin: 0px 0px 15px 0px;
  }
  .project-card {
    margin: 4px 0px 0px 0px;
    ion-card-header {
      padding: 12px 15px 0px !important;
      .card-title {
        .alert-icon {
          margin-right: 3px;
          margin-bottom: 0px;
        }
        font-size: 19px;
        margin-top: 3px;
      }
    }
    ion-card-content {
      padding: 8px 14px 14px 15px;
    }
    ion-button::part(native) {
      --padding-start: 0px;
      --padding-end: 4px;
    }
  }
  .ios {
    ion-searchbar {
      padding-bottom: 0px;
      padding-inline-end: 8px;
      padding-inline-start: 8px;
    }
  }
  .alert-feed-list {
    &.md {
      margin-top: 16px;
    }
    ion-item-group {
      border-radius: 10px !important;
      overflow: hidden !important;
      margin-bottom: 0px !important;
      ion-item-divider {
        --background: var(--ion-color-white);
        --padding-start: 6px;
        --padding-end: 0px;
        &.md {
          --padding-end: 10px;
        }
      }
    }
    .project-title-container {
      // text-align: center;
      padding: 5px 10px;
      border-radius: 7px;
      margin: 10px 0px 10px 4px;
      background: rgba(var(--ion-color-primary-rgb), 0.08);
      color: var(--ion-color-primary-shade);
      width: 100%;
      .project-title-wrap {
        font-size: 16px;
        // font-weight: 600;
      }
    }
    ion-item {
      --padding-bottom: 10px;
    }

    ion-label {
      text-overflow: ellipsis;
      white-space: unset;
      overflow: initial;
      display: -webkit-box;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      margin: 10px 8px 0px 0px !important;
    }
  }
}

.dark-theme {
  #alertsTab {
    background: #000000;
    ion-content::part(background) {
      --background: #000000;
    }
    .color-bg {
      --background: #000000;
      background: #000000;
    }
    .project-card {
      --background: #0e1113;
    }
    ion-searchbar,
    ion-segment {
      --background: #0e1113;
    }
    .alert-feed-list {
      background: #000000;
      ion-item-group {
        ion-item-divider {
          --background: #121212;
        }
      }
      ion-item {
        --background: #121212;
      }
      ion-label {
        span {
          color: #ebebf52e !important;
        }
      }
    }
    .project-title-container {
      background: rgba(var(--ion-color-primary-rgb), 0.17);
      color: var(--ion-color-primary-tint);
    }
  }
}

// .dark-theme {
//   #projectsTab {
//     background: #000000;
//     ion-content::part(background) {
//       --background: #000000;
//     }
//     .color-bg {
//       --background: #000000;
//       background: #000000;
//     }
//     .project-card {
//       --background: #121212;
//     }
//   }
// }
