#map-view {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  contain: layout size style;
  overflow: hidden;
  z-index: 0;
  .esri-ui-bottom-left {
    width: 100%;
    .map-logos {
      position: absolute;
      bottom: 0px;
      left: 0px;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 0px);
      img {
        width: 25%;
        height: auto;
        padding: 0px;
        margin: 10px 0px;
        line-height: 150px;
        color: white;
      }
      .cot {
        padding-bottom: 0px !important;
      }
      .tt {
        width: 40% !important;
        padding: 0px !important;
      }
      .pipes {
        padding-bottom: 0px !important;
      }
    }
  }

  #phases-filter {
    height: 200px;
    width: 100%;
    visibility: hidden;
  }

  .phase-item {
    width: 100%;
    padding: 12px;
    text-align: center;
    vertical-align: baseline;
    cursor: pointer;
    height: 40px;
  }

  .phase-item:focus {
    background-color: dimgrey;
  }

  .phase-item:hover {
    background-color: dimgrey;
  }

  background: #ebf4ff00;
  ion-content::part(background) {
    --background: #ebf4ff00;
  }
  .safe-area-header {
    height: var(--ion-safe-area-top, 0);
    -webkit-backdrop-filter: blur(7px);
    margin-bottom: calc(-1 * var(--ion-safe-area-top, 0));
    backdrop-filter: blur(7px);
    background-color: rgba(255, 255, 255, 0);
    position: relative;
    top: 0px;
    left: 0px;
    z-index: 10000000;
  }
  .mapDiv {
    height: 100%;
    width: 100%;
    padding: 0;
  }
  .network-error {
    height: 100%;
    width: 100%;
    // background: #ebf4ff;
    position: absolute;
    // backdrop-filter: blur(3px);
    // background-color: rgba(255, 255, 255, 0.7) !important;
    background: url(./explore-splash.png) center center / cover;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    z-index: 1;
  }
  .map-loading {
    height: 100%;
    width: 100%;
    background: #fff;
    // background: var(--background);
    // background: linear-gradient(
    //   317deg,
    //   rgba(0, 68, 112, 1) 0%,
    //   rgba(0, 124, 204, 1) 100%
    // );
    // backdrop-filter: blur(3px);
    // background-color: rgba(255, 255, 255, 0.2) !important;
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    z-index: 1;
  }

  ion-menu-button::part(icon) {
    --ionicon-stroke-width: 23px;
    // ion-icon::part() {
    //   div > svg > path {
    //     stroke-width: 20px;
    //   }
    // }
  }

  .show-map {
    opacity: 1;
  }

  .esri-popup__icon {
    color: #0072bc !important;
  }
  canvas:focus,
  div:focus {
    outline: none !important;
  }

  .esri-view-width-less-than-medium .esri-popup__action-text {
    display: block !important;
  }

  .esri-ui .esri-popup {
    border-radius: 15px;
    max-width: 300px;
    min-width: 280px;
    // display: none;
  }
  .esri-icon-description {
    display: none;
  }
  .esri-popup__content {
    margin: -2px 15px 8px 15px;
  }
  .esri-feature-content > div:nth-child(1) {
    line-height: 25px;
  }
  .esri-popup__footer {
    margin-top: -18px;
  }

  .esri-popup--is-docked {
    margin: 0px 0px 0px 0px !important;
    // animation: esri-docking-animation 250ms ease-out;
  }

  .esri-view-width-xsmall
    .esri-popup--is-docked-top-center
    .esri-popup__main-container,
  .esri-view-width-xsmall
    .esri-popup--is-docked-bottom-center
    .esri-popup__main-container {
    padding-bottom: var(--ion-safe-area-bottom, 0) !important;
  }

  // .esri-ui-top-left .esri-component {
  //   margin-top: calc(44px + env(safe-area-inset-top));
  // }
  .webmap .esri-ui-top-right .esri-component {
    margin-top: -8px;
  }
  .esri-home {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .esri-view .esri-view-surface--inset-outline:focus::after {
    display: none !important;
  }
  .esri-popup__inline-actions-container:only-child > .esri-popup__action,
  .esri-popup__inline-actions-container:only-child
    > .esri-popup__action-toggle {
    max-width: 50% !important;
  }
  .esri-view-width-less-than-medium .esri-popup__main-container {
    width: 100% !important;
  }
  .esri-popup__main-container {
    border-radius: 15px;
    padding: 0px 0px;
    overflow: hidden;
    box-shadow: var(--box-shadow) !important ;
  }
  .esri-popup__inline-actions-container:only-child
    .esri-popup__actions-menu-button {
    margin-left: 0px;
  }
  .esri-popup__inline-actions-container {
    justify-content: space-between !important;
    flex-direction: row-reverse !important;
    margin: 0 12px !important;
  }
  .esri-popup__header {
    padding-top: 3px;
  }
  .esri-popup__header-title {
    font-family: var(--ion-font-family, inherit);
    font-size: 19px !important;
    font-weight: 700 !important;
    color: #000;
    margin: 4px auto 7px 9px !important;
    text-align: left;
    width: 100%;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
  }
  .esri-popup__inline-actions-container:only-child > .esri-popup__action {
    color: var(--ion-color-primary) !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    align-items: center;
  }
  .esri-popup__action-text {
    margin-left: 7px;
    margin-bottom: -3px;
  }
  .esri-icon-close {
    font-weight: 600 !important;
  }
  .esri-popup__actions
    .esri-popup__button[class*='esri-popup__action']:last-child {
    border-bottom: none;
    color: var(--ion-color-primary);
    font-size: 16px;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 12px;
    .esri-popup__action-text {
      margin-top: 4px;
    }
  }

  .esri-popup__inline-actions-container .esri-popup__icon,
  .esri-popup__inline-actions-container [class*='esri-icon'] {
    margin: 0px;
  }

  [class*='esri-icon'] {
    font-size: 18px;
  }

  .esri-popup__actions {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px !important;
    border-radius: 7px;
    color: var(--ion-color-primary) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    bottom: 0px;
  }
  .esri-popup__button {
    border-radius: 7px !important;
  }
  .esri-feature__last-edited-info {
    display: none;
  }
  .esri-feature__content-element {
    padding: 3px !important;
    margin-bottom: 0px;
  }
  .esri-feature-media__container,
  .esri-feature-media__item {
    margin-top: 0px !important;
  }
  // .esri-popup--shadow {
  //   box-shadow: none !important;
  // }
  .esri-popup--shadow {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 10px !important;
  }
  *:focus {
    outline: none !important;
    outline-color: none !important;
    outline-style: none !important;
    outline-width: none !important;
  }
  .visible {
    display: block !important;
  }
  .loading {
    position: absolute;
    top: 100px;
    left: 100px;
    background-color: black;
    color: white;
    display: none;
    padding: 8px;
  }

  .esri-ui-corner-container {
    margin-top: var(--ion-safe-area-top, 0) !important;
    inset: 15px 15px 0px !important;
  }
  .esri-ui-corner .esri-component > .esri-widget--panel,
  .esri-ui-corner .esri-component.esri-widget--panel {
    margin-right: -10px;
  }
  .esri-ui-corner .esri-component {
    box-shadow: none !important;
  }
  .esri-layer-list {
    color: #323232;
    background-color: #fff;
    padding: 0px;
  }
  .esri-layer-list__list {
    padding: 0px;
    margin: 0 0 0 0px;
  }
  .esri-layer-list__item {
    border-bottom: 0px;
    margin: 0px;
    padding: 1px;
  }
  .esri-widget {
    font-size: 14px;
  }
  #17ab0030627-widget-2_17ab0030526-object-30__list > li > div {
    margin-left: -17px !important;
  }

  // .esri-layer-list__list--root {
  //   margin: 0;
  //   display: flex;
  //   flex-flow: row-reverse;
  //   padding: 0px 2px !important;
  // }
  // .esri-layer-list__item {
  //   background-color: #fff;
  //   border-bottom: 1px solid rgba(110, 110, 110, 0.3);
  //   position: relative;
  //   overflow: hidden;
  //   list-style: none;
  //   margin-top: 0px !important;
  //   margin-right: 0px !important;
  //   margin-bottom: 0px !important;
  //   margin-left: 10px !important;
  //   padding: 0;
  //   transition: background-color 125ms ease-in-out;
  //   width: 50%;
  //   display: inline-flex;
  //   border-radius: 8px;
  //   max-width: 125px;

  //   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  // }
  .esri-layer-list__item-container {
    // border-left: none !important;
    // display: flex;
    // justify-content: flex-start;
    align-items: flex-start;
    // padding: 8px 10px !important;
    padding: 9px 10px 6px 10px !important;
    margin: 0px important;
  }
  .esri-ui-corner .esri-component.esri-widget--panel {
    width: 145px;
  }
  div.esri-component.esri-home.esri-widget--button.esri-widget {
    margin-top: 3px !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4) !important;
  }
  .esri-ui-top-left {
    display: flex;
    flex-direction: column-reverse;
    top: -7px;
    left: -7px;
  }
  .esri-ui-top-right {
    top: -7px;
    right: -7px;
  }
  .esri-ui-top-left .esri-component {
    border-radius: 8px;
  }
  .esri-component .esri-zoom .esri-widget {
    border-radius: 8px;
  }
  .esri-zoom .esri-widget--button:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .esri-zoom .esri-widget--button:first-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .esri-widget--button {
    color: #0072bc !important;
    height: 38px;
    width: 38px;
    font-size: 18px !important;
  }
  .esri-zoom .esri-widget--button {
    border-radius: 7px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }
  .esri-layer-list__child-toggle {
    width: 21px;
    margin-top: -1px;
    color: #0072bc;
  }

  .esri-ui-corner .esri-component.esri-widget--panel {
    position: absolute;
    top: 0;
    left: 50px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  }
  .esri-layer-list__item--has-children > .esri-layer-list__list {
    font-size: 14px;
  }
  .esri-layer-list__item-toggle {
    color: #0066a8;
  }
  .esri-layer-list__item-title {
    font-weight: 500;
    color: #0066a8;
  }
  .esri-layer-list__item-toggle {
    color: #0072bc !important;
  }
  // .esri-layer-list__item-label {
  //   align-items: center;
  // }
  #actions-menu {
    background: none;
    margin-top: 0px;
    // margin-top: -55px;
    ion-button {
      --background-activated-opacity: 0;
      height: 38px;
      width: 38px;
      padding: 0px 0px;
      border-radius: 7px;
      margin: 0px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
      ion-spinner {
        transform: scale(1.5);
      }
    }
    ion-button::part(native) {
      box-shadow: none !important;
      border-radius: 7px;
    }
  }
  #actions {
    background: none;
    margin-top: 0px;
    ion-button {
      --background-activated-opacity: 0;
      height: 38px;
      width: 38px;
      padding: 0px 0px;
      border-radius: 8px;
      margin: 0px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
      ion-spinner {
        transform: scale(1.5);
      }
    }
    ion-button::part(native) {
      box-shadow: none !important;
      // border-radius: 8px;
      border-top: solid 1px #d6d6d6;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

#exploreTab {
  background: #fff;
  ion-content::part(background) {
    --background: #fff;
  }
}

.dark-theme {
  #exploreTab {
    background: #000000;
    ion-content::part(background) {
      --background: #000000;
    }
  }
  #map-view {
    background: #000000;
    ion-content::part(background) {
      --background: #000000;
    }
    .esri-popup__main-container {
      background-color: #1c1c1c;
      color: #fff;
    }
    .esri-popup--shadow {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 10px !important;
    }
    .esri-popup__button:hover {
      background-color: #1c1c1c !important;
    }
    .esri-popup__pointer-direction {
      background-color: #1c1c1c;
    }
    .esri-feature.esri-widget,
    // .esri-layer-list__item,
    .esri-widget--button {
      color: #fff;
      background-color: #0f1113 !important;
    }
    .esri-widget {
      background-color: #0f1113;
    }
    #actions,
    #actions-menu {
      ion-button::part(native) {
        background: #0f1113;
        border-top: solid 1px rgba(110, 110, 110, 0.3);
      }
    }
    .esri-popup__actions {
      background-color: #272727;
    }
    .esri-popup__button {
      color: #0072bc;
    }
    .esri-layer-list {
      background-color: #1c1c1c;
      .esri-layer-list__item {
        background-color: #1c1c1c;
      }
    }
    .esri-popup__header-title {
      color: #fff;
    }
    .network-error {
      background: url(./explore-splash-dark.png) center center / cover !important;
      .take-a-min {
        margin-top: 10px;
        font-size: 13px;
        color: #ffffff9f;
      }
    }
  }
}
